<template>
  <div v-loading="loading" class="main-frame">
    <el-container>
      <el-header class="banner-head">
        <div class="head-info">
          <el-row>
            <el-col style="text-align: left" :span="12">
              <el-page-header
                class="back"
                title="返回"
                @back="goBack"
                content="SEO生成站点地图详情"
              ></el-page-header>
            </el-col>
            <el-col style="text-align: right" :span="12">
              <el-button type="primary" icon="el-icon-check" @click="saveModelOnclick"
                >保存</el-button
              >
            </el-col>
          </el-row>
        </div>
      </el-header>
      <el-main>
        <el-form
          class="type-form"
          :model="edit_model"
          :rules="rules"
          ref="modelForm"
          label-width="100px"
        >
          <el-form-item label="相对路径:" prop="relative_path">
            <el-input v-model="edit_model.relative_path" clearable></el-input>
          </el-form-item>
          <el-form-item label="SEO名称:" prop="seo_title">
            <el-input v-model="edit_model.seo_title" clearable></el-input>
          </el-form-item>
          <el-form-item label="SEO描述:" prop="seo_description">
            <el-input
              v-model="edit_model.seo_description"
              clearable
              type="textarea"
              :rows="5"
            ></el-input>
          </el-form-item>
          <el-form-item label="权重:">
            <el-input-number
              v-model="edit_model.number"
              :min="0"
              label="权重"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="状态:">
            <el-select
              v-model="edit_model.status"
              placeholder="请选择"
              loading-text="加载中..."
            >
              <el-option
                v-for="item in $common.StatusSelectList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <CopyPaste
            :button_status="
              this.edit_model_id !== undefined && this.edit_model_id.length > 0
            "
            @copyInfo="copyInfo(edit_model)"
            @pasteInfo="pasteInfo"
            ref="copyPaste"
          ></CopyPaste>
        </el-form>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import CopyPaste from "../../components/CopyPaste.vue";

export default {
  name: "SeoDetailView",
  components: {
    CopyPaste,
  },
  data() {
    return {
      loading: false,
      rules: {
        seo_title: [
          { required: true, message: "请输入seo标题", trigger: "blur" },
          { min: 1, message: "长度大于 1 个字符", trigger: "blur" },
        ],
        seo_description: [
          { required: true, message: "请输入seo描述", trigger: "blur" },
          { min: 1, message: "长度大于 1 个字符", trigger: "blur" },
        ],
        relative_path: [
          { required: true, message: "请输入路径", trigger: "blur" },
          { min: 1, message: "长度大于 1 个字符", trigger: "blur" },
        ],
      },
      edit_model_id: "",
      edit_model: {
        id: "",
        relative_path: "",
        seo_description: "",
        seo_title: "",
        status: 2,
        number: 0,
      },
    };
  },
  created() {
    this.edit_model_id = this.$route.query.id;
    this.edit_model.relative_path = this.$route.query.path;
    if (this.edit_model_id !== undefined && this.edit_model_id.length > 0) {
      // 编辑
      this.getEditModel();
    }
  },
  methods: {
    getEditModel() {
      let _me = this;
      _me.loading = true;
      let params = {
        id: _me.edit_model_id,
        relative_path: _me.edit_model.relative_path,
      };
      _me.$common
        .httpPost("/api/seo/admin/sitemap/find/path", params)
        .then((res) => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.edit_model = res.data;
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err.message || "获取数据失败",
            type: "error",
          });
        });
    },
    saveModelOnclick() {
      let _me = this;
      this.$refs["modelForm"].validate((valid) => {
        if (valid) {
          if (this.edit_model_id !== undefined && this.edit_model_id.length > 0) {
            this.updateOneModel();
          } else {
            this.addNewModel();
          }
        } else {
          _me.$message({
            message: "请检查表单!",
            type: "error",
          });
        }
      });
    },
    domainURI(str) {
      let domain = "";
      if (str.indexOf("http") > -1 || str.indexOf("https") > -1) {
        let length = str.split("/").length;
        domain = "/" + str.split("/").slice(3, length).join("/");
      } else {
        domain = str;
      }
      return domain;
    },
    addNewModel() {
      let _me = this;
      let params = {
        relative_path: _me.domainURI(_me.edit_model.relative_path),
        seo_description: _me.edit_model.seo_description,
        seo_title: _me.edit_model.seo_title,
        status: _me.edit_model.status,
        number: _me.edit_model.number,
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/seo/admin/sitemap/add", params)
        .then((res) => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.$message.success("内容添加成功");
            _me.goBack();
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err.message || "获取数据失败",
            type: "error",
          });
        });
    },
    updateOneModel() {
      let _me = this;
      let params = {
        id: _me.edit_model.id,
        relative_path: _me.edit_model.relative_path,
        seo_description: _me.edit_model.seo_description,
        seo_title: _me.edit_model.seo_title,
        status: _me.edit_model.status,
        number: _me.edit_model.number,
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/seo/admin/sitemap/update", params)
        .then((res) => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.$message.success("内容保存成功");
            _me.goBack();
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err.message || "获取数据失败",
            type: "error",
          });
        });
    },
    goBack() {
      // 返回上一级路由
      this.$router.go(-1);
    },
    // 复制信息
    copyInfo(str) {
      // 触发赋值事件，将当前的数据传值
      this.$refs.copyPaste.CopyInfo(str);
    },
    // 粘贴信息
    pasteInfo(model) {
      // 接受粘贴事件的传值数据
      this.edit_model = model;
      this.edit_model.id = this.edit_model_id;
    },
  },
};
</script>

<style scoped>
.edit-row-dt {
  padding: 5px 0 0px 0;
  margin: 0;
  width: 100%;
}
</style>
